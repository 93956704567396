import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import { getPageNumbers, getStripData } from '../utils/pageUtils';

const Overlay = styled.div`
  background-color: #ddd;
  border-radius: 4px;
  height: 102%;
  left: 2%;
  max-width: 500px;
  opacity: 20%;
  position: absolute;
  top: -1%;
  width: 96%;
  z-index: 2;
`;

const ImgWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  width: 70vw;
`

const Div = styled.div`
  margin: 0 auto;
  max-width: 600px;
  position: relative;

  .home {
    right: 20px;
    position: fixed;
    bottom: 16px;
  }

  a {
    font-size: 36px;
  }

  .navigation {
    margin: -11px 0 0;
    position: absolute;
    top: 50%;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }

  @media (max-width:480px) {
    a {
      font-size: 28px;
    }

    .navigation {
      margin: -8px 0 0;
      top: 50%;
    }
  }
`;

const Page = ({ data }) => {
  const page = data.imageSharp;
  const { fields: { slug } } = page;
  const [ ,imageDir ] = slug.split('/');
  const { stripName, totalPages } = getStripData(imageDir);
  const { pageNumber, prevPageNumber, nextPageNumber } = getPageNumbers(page, totalPages);
  return (
    <Layout>
      <Div>
        <p className="navigation prev">
          <Link
            to={`/${imageDir}/page${prevPageNumber}`}
            className={`${prevPageNumber === "00" ? "hidden" : ""}`}
          >
            ◀
          </Link>
        </p>
        <ImgWrapper>
          <Overlay />
          <Img
            style={{width: '95%', margin: '0 auto'}}
            fluid={page.fluid}
            alt={`${stripName} page ${pageNumber}`}
          />
        </ImgWrapper>
        <p className="navigation next">
          <Link
            to={`/${imageDir}/page${nextPageNumber}`}
            className={`${nextPageNumber === "00" ? "hidden" : ""}`}
          >▶</Link>
        </p>
        <p>
          <Link to="/" className="home">⌂</Link>
        </p>
      </Div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    imageSharp(fields: { slug: { eq: $slug } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
      fields {
        slug
      }
    }
  }
`

export default Page;

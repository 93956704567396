const getPrevPageNumber = (pageNumber: number) => {
  const prevPageNum = pageNumber - 1;
  return prevPageNum > 9 ? String(prevPageNum) : `0${prevPageNum}`;
};
const getNextPageNumber = (pageNumber: number, totalPages: number) => {
  const nextPageNum = pageNumber === totalPages ? 0 : pageNumber + 1
  return nextPageNum > 9 ? String(nextPageNum) : `0${nextPageNum}`;
};

// TODO: type page
const getPageNumbers = (page, totalPages: number): {
  pageNumber: Number,
  nextPageNumber: String,
  prevPageNumber: String,
} => {
  const { src } = page.fluid;
  const pageNumber = Number(src.split('/').slice(-1)[0].match(/\d{2}/)[0]);
  const nextPageNumber = getNextPageNumber(pageNumber, totalPages);
  const prevPageNumber = getPrevPageNumber(pageNumber);
  return { pageNumber, nextPageNumber, prevPageNumber };
};

const getStripData = (dir: string): StripData => {
  const dirToStripName: Record<string, StripData> = {
    'solipsists-doodles': {
      stripName: 'Solipsist\'s Doodles',
      // TODO: derive this
      totalPages: 30,
    },
  };

  return dirToStripName[dir];
};

export {
  getPageNumbers,
  getStripData,
};
